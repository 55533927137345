.about-main{
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
}
.about{
    width: 1440px;
    overflow: hidden;
   
}
.about-head p{
    font-size: 72px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    line-height: 80px;

}
.about-para p{
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    color:rgba(75, 85, 99, 1) ;
    text-align: center;
    font-family: 'Inter', sans-serif;

}
.about-para{
    width: 72%;
    margin-top: 10px;
}
.about-img{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 50px;
}
.about-pic{
    width: 90%;
   
}
.about-pic img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.about-mission{
    margin-top: 100px;
    width: 100%;
  
    display: flex;
    justify-content: center;
}
.about-miss{
    width: 90%;
    display: flex;
    align-items: center;
}
.about-textt{
    width: 50%;
    display: flex;
    flex-direction: column;
}
.about-missimg{
    width: 50%;
    display: flex;
    justify-content: end;
}
.mission-picture{
    width: 85%;
}
.mission-flex{
    display: flex;
    justify-content: space-between;
    width: 60%;
}
.mission-head p{
    font-weight: 500;
    font-size: 42px;
    line-height: 50px;
    font-family: 'Inter', sans-serif;
}
.mission-text p{
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 24px;
    color: rgba(75, 85, 99, 1);
    line-height: 29px;

}
.mission-member{
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 24px;
    color: rgba(75, 85, 99, 1);
    line-height: 29px;
 

}
.mem p{
    color: black;
    font-family: 'Inter', sans-serif;
   font-weight: 500;
   font-size: 32px;
   line-height: 39px;
}
@media only screen and (max-width: 1190px) {
    .about-shadow .partner-shadow-text p{
        font-size: 60px;
    }
    .about-head p{
        font-size: 60px;
    }
}
@media only screen and (max-width: 1300px) {
   .mission-picture img{
    width: 100%;
   }
   .mission-flex{
    width: 70%;
   }
}
@media only screen and (max-width: 1000px) {
    .mission-head p{
        font-size: 35px;
    }
    .mission-text p{
        font-size: 20px;
    }
    .mission-member{
        font-size: 20px;
    }
    .mem p{
        font-size: 25px;
    }
    .about-shadow .partner-shadow-text p{
        font-size: 55px;
    }
    .about-head p{
        font-size: 55px;
    }
}
@media only screen and (max-width: 890px) {
    .about-shadow .partner-shadow-text p{
        font-size: 50px;
        line-height: 80px;
    }
    .about-shadow .partner-shadow-text .partner-shadow{
        height: 80px;
    }
    .about-head p{
        font-size: 50px;
        line-height: 60px;
    }
}
@media only screen and (max-width: 780px) {
    .about-shadow .partner-shadow-text p{
        font-size: 43px;
    }
    .about-head p{
        font-size: 43px;
        
    }
}
@media only screen and (max-width: 1000px) {
    .mission-flex{
        width: 90%;
    }
}
@media only screen and (max-width: 700px) {
    .about-miss{
        flex-direction: column;
    }
    .about-textt{
        width: 100%;
        text-align: center;
    }
    .mission-flex{
        width: 100%;
        justify-content: space-around;
    }
    .about-missimg{
        width: 100%;
        justify-content: center;
    }
    
}
@media only screen and (max-width: 650px) {
    .about-head p{
        line-height: 0px;
        font-size: 50px;
    }
    .about-shadow .partner-shadow-text p{
        font-size: 50px;
        line-height: 70px;
    }
    .about-shadow .partner-shadow-text .partner-shadow{
        height: 70px;
    }
  
    .about-shadow .about-para p{
        font-size: 18px;
    }
    .about-shadow .about-para{
        margin-top: 30px;
        width: 90%;
    }
}
.team-cont{
    width: 100%;
    display: flex;
    justify-content: center;

}
.team{
    display: flex;
    flex-wrap: wrap;
    width: 70%;
    justify-content: space-between;
}
.team-profile{
    width: 100%;
    height: 370px;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    border:1px solid rgba(243, 244, 246, 1) ;
    align-items: center;
    margin-bottom: 30px;
    border-radius: 12px;
}
.team-profile-pic{
    width: 90%;
    margin-bottom: 20px;
    border-radius: 12px;
}
.team-profile-pic img{
    width: 100%;
    object-fit: contain;
}
.team-name{
    width: 90%;
    display: flex;
    justify-content: start;
    align-items: center;
}
.team-name p{
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 22px;
}
.team-title{
    width: 100%;
    margin-left: 10%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.team-title p{
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 10px;
    color: rgba(75, 85, 99, 1);
    
}
@media only screen and (max-width: 375px) {
    .team-title p{
        line-height: 20px;
    }
}
@media only screen and (max-width: 1170px) {
    .team{
        justify-content: space-evenly;
    }
}
@media only screen and (max-width: 780px) {
    .team-profile{
        margin-bottom: 10px;
        height: 350px;
    }
}
.about-app .empty{
    height: 20px;
}