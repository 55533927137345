.faq-main{
    max-width:100% ;
    overflow: hidden;
    display: flex;
    justify-content: center;
}
.faq{
    width: 1440px;
    overflow: hidden;
}
.faqs p{
    font-size: 72px;
    font-weight: 500;
    line-height: 100px;
    font-family: 'Inter', sans-serif;
}
.faq-head{
    width: 100%;
    display: flex;
    margin-top: 70px;
    flex-direction: column;
    align-items: center;
}
.faq-content{
    width: 75%;
    padding-right: 30px;
    padding-left: 30px;
}
.faq-main-text p{
    font-size: 32px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    line-height: 24px;
}
.faq-child-text p{
    font-size: 24px;
    font-weight: 400px;
    line-height: 29px;
    color:rgba(75, 85, 99, 1) ;
    font-family: 'Inter', sans-serif;

}
.faqs-cont{
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    
}
.faq-icon button{
    background-color: transparent;
    border: none;
    font-size: 40px;
}
@media only screen and (max-width: 1350px) {
    .faq-content{
        width: 90%;
    }
}
@media only screen and (max-width: 1150px) {
    .faq-main-text p{
        font-size: 27px;
    }
    .faq-child-text p{
        font-size: 20px;
    }
}
@media only screen and (max-width: 950px) {
    .faq-main-text p{
        font-size: 23px;
    }
    .faq-child-text p{
        font-size: 17px;
    }
    .faqs p{
        font-size: 50px !important;
    }
}
@media only screen and (max-width: 850px) {
    .faq-head{
        margin-top: 20px;
    }
}
@media only screen and (max-width: 650px) {
    .faq-main-text p{
        font-size: 20px;
        text-align: start;
    }
    .faq-child-text p{
        font-size: 15px;
        line-height: 20px;
    }
    .faq-content{
        width: 100%;
    }
    .faq-icon button{
        font-size: 30px;
    }
    .faqs-cont{
        margin-top: 20px;
    }
    .faqs p{
        font-size: 40px !important;
    }
}







.questions{
    width: 100%;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.questions-text{
    width: 80%;
    display: flex;
    align-items: center;
    flex-direction: column;
    
}
.questions-text :nth-child(1){
    font-family: "Unbounded", sans-serif;
    font-size: 45px;
    font-weight: 400;
    line-height: 55px;
    text-align: center;
    color: rgba(30, 30, 30, 1);
    /* padding-bottom: 15px; */
}
.questions-text :nth-child(2){
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color:rgba(25, 29, 35, 1) ;
    font-family: "Unbounded", sans-serif;
}
.questions-data{
    width: 90%;
    
}
.question {   
   padding: 15px;
    background-color: rgba(6, 132, 102, 1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 16px;
    margin-bottom: 35px;
    margin-top: 10px;
    transition: height 2s, padding 0.3s, opacity 0.3s;
    cursor: pointer;
}

.answer {
    width: 100%;
    background-color: white;
    height: auto;
    border-radius: 7px;
    box-sizing: border-box;
   padding: 10px;
    transition: height 0.3s, opacity 0.3s;
}

.answer p {
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: rgba(30, 30, 30, 1);
    margin-bottom: 0;
}

.question-mark {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
}

.question-mark p {
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    font-family: "Plus Jakarta Sans", sans-serif;
    color: white;
    margin: 0;
}

.question img {
    width: 20px;
}

/* CSS for hiding the answer container */
/* CSS for hiding the answer container */
.answer-hidden {
   padding: 0;
    height: auto;
    color: black;
    overflow: hidden;
    transition: height 0.3s, padding 0.3s, opacity 0.3s;
    justify-content: center;
    
}
.answer-hidden .question-mark{
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0px 30px 0px 30px;
    background-color: white;
    
}
.answer-hidden .question-mark p{
   margin-bottom: 0;
    
}
.answer-hidden .answer p {
display: none;
}

.answer-hidden .question-mark p {
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
   
}

.questions-main.answer-shown .questions {
    background-color: white;
    height: 156px; /* Adjust this height to your preference */
     transition: height 0.3s, padding 0.3s, opacity 0.3s;
}
@media screen and (max-width:900px) {
    .questions-text :nth-child(1){
        font-size: 35px;
    }
    .questions-text :nth-child(2){
        font-size: 20px;
    }
    .questions-main{
        margin-top: 50px;
    }
    .answer-hidden .question-mark{
        height: 70px;
    }
    
}
@media screen and (max-width:500px) {
    .questions-text :nth-child(1){
        font-size: 20px;
    }
    .questions-text :nth-child(2){
        font-size: 14px;
    }
    .questions-main{
        margin-top: 50px;
    }
    
}


