.feature-main{
    width: 100%;
   overflow: hidden;
    display: flex;
    justify-content: center;
}
.feature{
    width: 1440px;
    overflow: hidden;
}
.feature .partner-shadow-text{
    text-align: center;
}
.icon-featuress{
    margin-top: 50px;
}
.feature-icons{
    display: flex;
    flex-wrap: wrap;

    justify-content: space-between;
    width:80% ;
    
}
.featuress{
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 40px;
}
.icons-flex{
    display: flex;
    justify-content: center;
    width: 100%;
}
.feature-icon-img img{
    width: 52px;
    height: 52px;
}
.feature-icon-head p{
    font-weight: 500;
    font-size: 32px;
    line-height: 39px;
    font-family: 'Inter', sans-serif;
}
.feature-icon-text p{
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
    color: rgba(75, 85, 99, 1);
}
@media (max-width: 750px) {
    .featuress{
        width: 100%;
    }
}

