.footer-main {
    width: 100%;
    display: flex;
    margin-top: 150px;
  }
  
  .footer-icons {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .footer-logo img {
    width: 217px;
  }
  
  .footer-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .footer-social {
    display: flex;
    width: 76%;
    
    justify-content: space-evenly;
    margin-top: 40px;
  }
  
  .footer-social img {
    width: 40px;
  }
  
  .footer-links {
    width: 75%;
    display: flex;
    justify-content: center;
    color: rgba(75, 85, 99, 1);
  }
  
  .footer-links p {
    text-decoration: none;
    padding-top: 10px;
  }
  
  .footer-box {
    width: 20%;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 24px;
  }
  
  @media screen and (max-width: 800px) {
    .footer-main {
      flex-direction: column;
      margin-top: 40px;
    }
  
    .footer-icons {
      width: 100%;
      margin-bottom: 20px;
    }
  
    
  
    .footer-links {
      width: 100%;
      flex-direction: column;
      align-items: center;
    }
  
    .footer-box {
      width: 90%;
      text-align: start;
      margin-bottom: 10px;
      font-size: 20px !important;
    }
    .footer-social img{
        width: 20px;
    }
    .footer-right p{
      font-size: 15px;
    }
   
  }
  
  @media screen and (max-width: 850px) {
    .footer-box {
      font-size: 10px;
    }
    .footer-logo img {
      width: 150px;
    }
    .footer-social {
      width: 30%;
      margin-top: 10px;
    }
    .footer-social img{
      width: 30px;
    }
  }
  

  

  
  .footer-right {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    color: rgba(75, 85, 99, 1);
    height: 70px;
  }
  
  .Link {
    text-decoration: none;
  }
  
  .contact-dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(0px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9998;
  }
  
  .contact-dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 9999;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 40px;
    border-radius: 12px;
    width: 50%;
    height: auto;
  }
  .dilog-box{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  
  }
  .dilog-content{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px;

  }
  .dilog-head p{
    font-size:42px ;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    line-height: 59px;
    text-align: center;
  }
  .dilog-head{
    width: 90%;
  }
  .dilog-para p{
    font-family: 'Inter', sans-serif;
    font-size: 24px ;
    font-weight: 400;
    line-height: 29px;
    color: rgba(75, 85, 99, 1);
  }
  .dilog-inputs{
    width: 100%;
    margin-top: 20px;
  }
  .dilog-inputs input{
    width: 100%;
    height: 65px;
    border: 1px solid rgba(243, 244, 246, 1);
    padding-left: 30px;
  }
  .dilog-inputs textarea{
    width: 100%;
    margin-top: 20px;
    border: 1px solid rgba(243, 244, 246, 1);
    outline: none;
    padding-left: 30px;
  }
  .dilog-inputs textarea::placeholder{
    color:rgba(75, 85, 99, 0.466);

  }
 
  .inputs-di{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .inputs-di input{
    width: 40%;
  }
  .dilog-link{
    width: 100%;
  }
  .dilog-link p{
    font-family: 'Inter', sans-serif;
   font-size: 16px;
   font-weight: 400;
   line-height: 25px;
  }
  .dilog-button{
    width: 317px;
    height: 64px;
    background-color:rgba(6, 132, 102, 1) ;
    color:rgba(255, 255, 255, 1) ;
    font-size: 22px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
   border: 1px solid rgba(243, 244, 246, 1);
   border-radius: 4px;
   
  }
  .icon-cross-dilog{
    display: flex;
    justify-content: end;
    
  }
  .icon-cross-dilog .cross-icon{
    font-size: large;
    cursor: pointer;
  }
  .icon-cross-dilog .cross-icon:hover{
    color: red;
  }
  @media screen and (max-height:750px) {
    .contact-dialog{
      height: 90% !important;
      overflow: scroll;
    }
    .dilog-content{
      padding: 10px;
    }
    
  }
  @media screen and (max-width: 1695px) {
    .contact-dialog{
      width: 60%;
    }
    .dilog-head p{
      font-size: 35px !important;
    }
    .dilog-para p{
      font-size: 20px;
    }
    .dilog-inputs input{
      height: 50px;
    }
    .dilog-button {
      height: 50px;
      width: 250px;

    }
    
  }
  @media screen and (max-width: 1210px) {
    .dilog-head p{
      font-size: 35px !important;
    }
    .dilog-para p{
      font-size: 20px;
    }
    .dilog-inputs input{
      height: 50px;
    }
    .dilog-button {
      height: 50px;
      width: 250px;

    }
  
   
  }
  @media screen and (max-width: 1500px) {
    .dilog-head{
      width: 100%;
    }
    .dilog-head p{
      font-size: 40px;
    }
    .contact-dialog{
      width: 70%;
      height: auto;
    }
  }
  @media screen and (max-width: 1000px) {
    .dilog-content{
      width: 100%;
    }
    .dilog-head p{
      font-size: 30px !important;
    }
    .dilog-para p{
      font-size: 16px;
    }
    .icon-cross-dilog{
      width: 99%;
    }

  }
  @media screen and (max-width: 1000px) {
  }
  @media screen and (max-width: 850px) {
    .contact-dialog{
      width: 80%;
    }
    .dilog-head p{
      font-size: 35px;
      text-align: center;
    }
    .dilog-para p{
      font-size: 20px;
      text-align: center;
    }
  }
  @media screen and (max-width: 735px) {
    .contact-dialog{
      padding: 0px !important;
    }
    .dilog-box{
      width: 100%;
    }
    .dilog-content{
      width: 100%;
    }
    .dilog-head p{
      font-size: 15px;
    }
  }
  @media screen and (max-width: 650px) {
    .dilog-head p{
      font-size: 22px !important;
      line-height: 30px;
    }
    .inputs-di{
      flex-direction: column;
      margin-top: 1px;
    }
    .inputs-di input{
      width: 100%;
      margin-top: 20px;
    }
  }
  @media screen and (max-width: 570px) {
    .dilog-content{
      padding: 30px;
    }
  }
  @media screen and (max-width: 470px) {
    .dilog-content{
      padding: 10px;
    }
    .dilog-inputs input{
      height: 40px;
      padding-left: 10px;
    }
    .dilog-head p{
      font-size: 20px !important;
    }
    .dilog-para p{
      font-size: 15px;
    }
    .dilog-inputs{
      margin-top: 5px;
      
    }
    .contact-dialog{
      height: auto;
      width: 90%;
      padding: 30px !important;
    }
    .dilog-link p{
      font-size: 15px;
      line-height: 15px;
    }
    .dilog-button{
      width: 150px;
      height: 40px;
      font-size: 15px;
    }
  }