@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');
.retail-div{
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.retaill{
    width: 1440px;
    overflow: hidden;
}
.retail-main{
    width: 100%;
    display: flex;
    box-sizing: border-box;
    margin-bottom: 40px;
    margin-top: 100px;
}
@media screen and (max-width: 840px) {
    .retail-main{
        flex-direction: column;
        width: 100%;
        align-items: center;
        margin-top: 10px;
    }
    .retail-text{
        width: 100% !important;
    }
    .retail-head{
        width: 100% !important;
        display: flex;
        align-items: center;
        height: 150px !important;
    }
    .retail-para{
        width: 100% !important;
        text-align: center;
    }
    
    .retail-pic{
        width: 100%;
    }
    .retail-button button{
        width: 130px !important;
    }
    .retail-button{
        padding-left: 16%;
    }
    .retail-pic{
        width: 100% !important;
    }


 
}
@media screen and (max-width: 540px) {
    .retail-head p{
        font-size: 23px !important;
    }
    .retail-para p{
        font-size: 15px;
    }

}

@media screen and (max-width: 530px) {
    .retail-button button{
        width: 100px !important;
        
    }
    .ratings1 .rating-pics{
        width: 70px;
        padding-left: 20px;
    }
    .ratings1 .rating-pics .pics{
        margin-left: -10px;
    }

}
@media screen and (max-width: 640px) {
    .ratings1{
        margin-left: 70% !important;
    }
}
@media screen and (max-width: 750px) {
    .retail-button{
        flex-direction: column;
        padding-left: 0px;
        
    }
    .ratings1{
        margin-left: 60%;
    }
    .retail-button button{
        width: 150px !important;
    }
  
    .star-box1{
        width: 9000%;
    }
    .star-box1 .stars-text{
        width: 100%;
    }
    .retail-button{
    }
   
}
@media screen and (max-width: 560px) {
    .ratings1{
        margin-left: 80% !important;
    }
}

@media screen and (max-width: 450px) {
    .ratings1{
      
        padding-left: 30% !important;
    }
}
@media screen and (max-width: 380px) {
    .ratings1{
      
        padding-left: 60% !important;
    }
}
@media screen and (max-width: 500px) {
    .ratings1{
        margin-left: 90% !important;
    }
}
@media screen and (max-width: 630px) {
    
}
.retail-text{
    width: 60%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.retail-pic{
    width: 49%;
    display: flex;
    justify-content: center;
}
.retail-head p{
    font-family: 'Inter', sans-serif;
   font-weight: 500;
   font-size: 72px;
   display: flex;
   flex-direction: column;
}
.retail-head{
    width: 85%;
    display: flex;
    flex-direction: column;
}
.boost{
    display: flex;
    align-items: center;
}
.retail-boost{
    height: 80px;
    display: flex;
    align-items: center;
}
.retail-rot2{
    background-color:rgba(6, 132, 102, 1) ;
    width: 200px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 8px;
    transform: rotate(-4deg);
    color: white;
    height: 90px;
    display: flex;
    align-items: center;
    padding-top: 8px;
}
.shadow2{
    background-color: rgba(180, 218, 209, 1);
    width: 180px;
    margin-left: 15px;
  border-radius: 8px;
  transform: rotate(4deg);
  box-shadow: none;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 1340px) {
    .retail-head p{
        font-size: 50px;
    }
    .retail-rot2{
        width: 150px;
        height: 70px;
    }
    .shadow2{
        width: 130px;
    }
}
@media screen and (max-width: 1000px) {
    .retail-head p{
        font-size: 40px;
    }
    .retail-rot2{
        height: 60px;
        width: 115px;
    }
    .shadow2{
        margin-bottom: 20px;
        width: 100px;
    }
    .retail-head{
        height: 200px;
    }
 
}
@media screen and (max-width: 870px) {
    .retail-head p{
        font-size: 35px;
    }
    .retail-rot2{
        width: 100px;
        height: 50px;
        padding-left: 8px;
        padding-top: 10px;
    }
    .shadow2{
        width: 80px;
        height: 50px;
        
    }

}
@media screen and (max-width: 665px) {
    .retail-rot2{
        height: 45px;
        padding-top: 15px;
    }
    .shadow2{
        height: 45px;
    }
}
@media screen and (max-width: 540px) {
       .retail-rot2{
        width: 70px !important;
        height: 40px;
       }
       .shadow2{
        width: 60px;
        height: 40px;
        margin-bottom: 30px;
       }
       .retail-boost{
        margin-bottom: 10px;
       }
    
}
.head-text{
  
    display: flex;
    align-items: center;
    text-align: left;
    
}
.retail-img{
    width: 70%;
   height: 635px;
    border-radius: 8px;
}
.retail-img img{
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 8px;
}
@media screen and (max-width: 1340px) {
    .retail-img{
        height: 500px;
    }
}

@media screen and (max-width: 1000px) {
  
  .retail-img{
    width: 80%;
  }
  .head-text{
    height: 90px;
  }
}
@media screen and (max-width: 870px) {
   
    .retail-img{
        height: 400px;
    }
    .head-text{
        height: 70px;
      }
      .retail-rot{
        height: 50px;
      }
      .retail-head{
        height: 200px;
      }
      .retail-button{

      }
}
@media screen and (max-width: 750px) {
  
    .head-text{
        height: 60px;
      }
}
@media screen and (max-width: 665px) {
    .retail-head p{
        font-size: 25px;
    }
    .retail-img{
        height: 400px;
    }
    .retail-rot{
        height: 40px;
    }
    .head-text{
        height: 30px;
      }
      .retail-head{
        height: 150px;
      }
}
@media screen and (max-width: 540px) {
    .retail-head p{
        font-size: 22px;
    }
    .head-text{
        height: 10px;
      }
    
}
@media screen and (max-width: 480px) {
    .retail-head p{
        font-size: 18px;
    }
    .retail-img{
        height: 250px;
    }
    .retail-rot{
        height: 35px;
    }
    .head-text{
        height: 1px;
      }
     
}
@media screen and (max-width: 410px) {
    .retail-head p{
        font-size: 15px;
    }
    .head-text{
        height: 1px;
      }
      .retail-rot{
        height: 30px;
    }
      
}
.retail-phonee{
    display: flex;
}
.retail-phonee .phone-cont2{
    width: 70%;
   
}
.retail-phonee .phone-cont{
    width: 70%;
}
.phone3{
    width: 70%;
    height: 420px;
}
.phone3 img{
    width: 620px;
    height: 400px;
}
.phone4{
    width: 70%;
    display: flex;
    justify-content: end;
    height: 420px;
}
.phone4 img{
    width: 620px;
    height: 400px;
}
.para-main{
    width: 85%;
}
.retail-para{
    width: 85%;
    font-family: 'Inter', sans-serif;
   font-size: 24px;
   font-weight: 400;
  margin-right: 340px;
  color: rgba(75, 85, 99, 1);
}
@media screen and (max-width: 650px) {
    .retail-phonee .phone-cont{
        width: 100% !important;
        margin: 0 !important;
    }
    .retail-phonee .phone-head{
        height: 30px;
    }
    .retail-phonee .phone-cont2{
        width: 100%  !important; 
    }
    .phone3{
        width: 90% !important;
        height: 300px;
    }
    .phone3 img{
        width: 100%;
        height:100%;
    }
    .phone4{
        width: 90% !important;
        height: 300px;
    }
    .phone4 img{
        width: 100%;
        height:100%;
    }
    .retail-phonee .phone-textt{
        width: 90%;
    }
    .retail-phonee .phone-head{
        margin-top: 20px;
    }

}
@media screen and (max-width: 1110px) {
    .retail-phonee{
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-bottom: 40px;
    }
    .retail-phonee .card-2{
        flex-direction: column-reverse;
       
    }
    .retail-phonee .phone4{
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    .retail-phonee .phone-text{
        width: 100% !important;
        justify-content: center;
        display: flex;
        align-items: center;
       
    }
    .retail-phonee .phone-cont{
        width: 65%;
        margin: 0 !important;
    }
    .retail-phonee .phone-head{
        text-align: center;
    }
    .retail-phonee .phone-textt{
        text-align: center;
    }
    .retail-phonee .phone3{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .retail-phonee .phone-text2 {
        width: 100% !important;
        justify-content: center;
        display: flex;
        align-items: center;
        
    }
  
    .retail-phonee .phone-cont2{
        width: 70%;
        
    }
    

}
@media screen and (max-width: 1440px) {
   .retail-para{
    width: 80%;
   }
}
@media screen and (max-width: 1000px) {
    .retail-para{
        font-size: 20px;
    }
}

.retail-button{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 85%;
   
}
.retail-button button{
    margin-top: 40px;
}
.phone-texttt{
    width: 80%;
    text-align: start;
    padding-right: 100px;
    box-sizing: border-box;
}
.retail-button .starts{
    margin-right: 460px;
    width: 50%;
}
.retail-button .pics{}
.retail-text2{
    font-family: 'Inter', sans-serif;
   font-weight: 500;
   font-size: 42px;
   margin-left: 4%;
   margin-top: 80px;
}
.retail-text2 img{
    width: 96px;
    margin-top: 20px;
    margin-left: 30px;
}
.style-text{
    display: flex;
    align-items: center;
    height: 1px;
}
.text2{
    height: 90px;
}
@media screen and (max-width: 1000px) {
    .retail-text2{
        margin-top: 10px;
    }
    .green-button button img{
        width: 35px;
    }
}
.ratings1 {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    height: auto;
}

.rating-pics2 {
    display: flex;
    justify-content: flex-end; /* Align pictures to the right */
    width: 15%;
}
.retail-button .star-box1{
    margin-bottom: 18px;
    height: 20px;
    margin-right: 50px;
}
.retail-button .star-box1 img{
    width: 28px;
    padding-right: 5px;
}


.retail-button .pics {
    width: 206px;
    height: 76px;
    border-radius: 50%;
    margin-left: -5%;
    margin-right: 5px; /* Adjust this value based on your preference */
    z-index: 1; /* Ensure pictures are positioned above each other */
}
.retail-button .pics img{
    object-fit: contain;
    height: 100%;
    width: 100%;
}
@media screen and (max-width: 840px) {

}
@media screen and (max-width: 1440px) {
    .retail-button button{
        width: 160px;
    }
   
}
@media screen and (max-width: 1140px) {
    .retail-button .rating-pics2{
        width: 200px;
        margin-left: 30px;
    }
    .retail-button .star-box1 img{
        width: 20px;
    }
    .ratings1 .star-box1{
        width:100% ;
    }
    .ratings1 .stars-text{
        width: 300%;
    }
   .ratings1 .starts{
        margin-top: 5px;
    }
}
@media screen and (max-width: 1050px) {
    .retail-button .rating-pics2{
        width: 180px;
    }
}
@media screen and (max-width: 1000px) {
    .retail-button .rating-pics2{
        width: 150px;
    }
    .ratings1 .starts{
        height: 8px;
        margin-top: 10px;
       
    }
    .ratings1 .star-box1{
        width: 300%;
    }
    .ratings1 .stars-text{
        font-size: 15px;
    }
}
@media screen and (max-width: 950px) {

    .retail-button .rating-pics2{
        width: 130px;
    }
    .ratings1 .starts{
        margin-top: 0;
    }
}
@media screen and (max-width: 800px) {
    .retail-text2{
        font-size: 30px;
    }
   

}
@media screen and (max-width: 500px) {
    .retail-text2{
        font-size: 20px;
        
    }
    .text2{
        height: 40px;
    }
    .retail-text2 img{
        width: 50px;
    }
    .green-button{
       margin-top: 20px !important;
    }
    .retail-footer .footer-main{
        margin-top: 40px;
    }
}
.green-button{
    display: flex;
    justify-content: flex-start;
    margin-top: 60px;
    margin-left: 4%;
}
.retail-steps{
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
    justify-content: space-around;
   
}
.step-img{
    width: 41%;
    height: 600px;
    border-radius: 12px;
}
.step-img img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 12px;
}
.font1{
    font-size: 18px;
    font-family: 'Inter', sans-serif;

}
.font2{
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    color: rgba(75, 85, 99, 1);

}

.step-img img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.steps{
    width: 40%;
    padding: 20px;
    padding-left: 25px;
    box-sizing: border-box;
    border-radius: 12px;
    box-shadow: 5px 5px 48px -12px rgba(16, 12, 40, 0.18) ;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.step{
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
@media screen and (max-width: 1000px) {
    .step-img img{
        height: 500px;
    }
    .retail-steps{
        margin-bottom: 0px !important;
       height: 500px;
    }
    .steps{
        height: 500px;
    }
    .step{
        font-size: 15px !important;
    }
    .font1{
        font-size: 12px;
    }
    .font2{
        font-size: 15px;
    }
}
.empty{
    height: 30px;
}
@media only screen and (max-width: 750px) {
    .retail-steps{
        flex-direction: column;
        height: auto;
        align-items: center;
    }
    .step-img{
        width: 90%;
        justify-content: center;
        height: 500px;
    }
    .step-img img{
        width: 100%;
        
        object-fit: cover;
    }
    .steps{
        width: 90%;
        margin-top: 20px;
    }
}
