.partner-main{
    max-width: 100%;
    justify-content: center;
    display: flex;
    overflow: hidden;
}
.partner{
    width: 1440px;
}
@media only screen and (max-width: 550px) {
    .partner{
        margin-left: 10px !important;
        margin-right: 10px !important;
    }

}
.partners-text{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 90px;
}
.partner-shadow-text{
    display: flex;
    width: 100%;
    justify-content: center;
    font-family: 'Inter', sans-serif;
   font-weight: 500;
   font-size: 72px;
   line-height: 100px;
}
.p-shadow-text{
    color: white;
    background-color: rgba(6, 132, 102, 1);
    border-radius: 8px;
    transform: rotate(-4deg);
    padding-left: 5px;
    padding-right: 5px;
}
.partner-shadow{
    background-color:rgba(180, 218, 209, 1) ;
    height: 100px;
    transform: rotate(4deg);
    border-radius: 8px;
    margin-left: 10px;
    margin-right: 10px;
}

.partner-para p{
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    color:rgba(75, 85, 99, 1) ;
    text-align: center;
    font-family: 'Inter', sans-serif;

}
.partner-para{
    width: 70%;
}
.partner-button{
    font-family: 'Inter', sans-serif;
    color: white;
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;
    width: 235px;
    height: 64px;
    background-color: rgba(34, 34, 34, 1);
    border-radius: 12px;
    margin-top: 10px;

}

.partner-head{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    font-size: 32px;
    font-family: 'Inter', sans-serif;
    line-height: 39px;
    font-weight: 500;
    margin-top: 120px;
}
.logoos{
    width: 140px;
    height: 80px;
    margin-top: 64px;
}
.logoos img{
   width: 100%;
   height: 100%;
   object-fit: contain;
}
.partner-logo {
    display: flex;
    width: 70%;
    flex-wrap: wrap;
    justify-content: space-between;
}


@media only screen and (max-width: 950px) {
    .partner-shadow-text{
        font-size: 60px;
    }
    .logoos{
        width: 100px;
        height: 60px;
    }
    .partner-logo{
        width: 80%;
    }
  
}
@media only screen and (max-width: 850px) {
    .partner-shadow-text{
        font-size: 50px;
    }
    .partner-para p{
        font-size: 20px;
    }
}
@media only screen and (max-width: 650px) {
    .partner-shadow-text{
        font-size: 45px;
        line-height: 70px;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
    }
    .partner-shadow{
        height: 70px;
    }
    .partner-button{
        width: 150px;
        font-size: 15px;
        height: 50px;
    }
    .partners-text{
        margin-top: 30px;
    }
    .partner-para{
        width: 90%;
        text-align: center;
    }
    .partner-para p{
        font-size: 16px;
    }
    .partner-log {
        width: 50%; 
        justify-content: center;/* Two images in a row on screens wider than 650px */
    }
    .logoos{
        width: 100%;
        height: 60px;
        margin-top: 30px;
       
    }
    .partner-head{
        margin-top: 50px;
    }
    .partner-footer .footer-main{
        margin-top: 40px;
    }
   
}