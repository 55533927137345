.poss-main{
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
}
.poss{
    max-width: 1440px;
}
.pos-home{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 80px;
}
.pos-text{
    width: 40%;
    font-size:72px ;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    line-height: 100px;

}
.pos-img{
    width: 50%;
    display: flex;
    justify-content: end;
    align-items: flex-start;
}
.pos-home-img{
    width: 85%;
}
.pos-home-img img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.pos-shadow{
    display: flex;
}
.transactions{
    background-color:rgba(6, 132, 102, 1) ;
    transform: rotate(-4deg);
    padding-left: 10px;
    padding-right: 10px;
   border-radius: 8px;
   color: white;
}
.shadow-pos{
    background-color:rgba(180, 218, 209, 1);
    transform:  rotate(4deg);
    height: 100px;
   border-radius: 12px;
   margin-right: 5px;
   
}
.pos-para p{
    font-size: 24px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    line-height: 29px;
    color: rgba(75, 85, 99, 1);
}
.pos-para{
    width: 90%;
}
.pos-home-button button{
    background-color: rgba(34, 34, 34, 1);
    color: white;
    font-family: 'Inter', sans-serif;
    font-size: 22px;
    font-weight: 500;
    width: 213px;
    height: 65px;
    line-height: 27px;
    border-radius: 8px;
    border: none;
}
@media screen and (max-width: 1250px) {
    .pos-text{
        font-size: 60px;
    }
    .pos-para{
        width: 100%;
    }
    .pos-para p{
        font-size: 22px;
    }
    .pos-home-img{
        height: 420px;
    }
}
@media screen and (max-width: 1000px) {
    .pos-text{
        font-size: 50px;
    }
    .pos-para p{
        font-size: 20px;
    }
    .pos-home-button button{
        width: 180px;
        font-size: 17px;
    }
    .pos-home-img{
        height: 350px;
    }
}
@media screen and (max-width: 850px) {
    .pos-home{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .pos-text{
        width: 70%;
        text-align: center;
        font-size: 70px;
    }
    .pos-shadow{
        justify-content: center;
    }
    .pos-para p{
        font-size: 25px;
    }
    .pos-home-button button{
        width: 200px;
        font-size: 22px;
    }
    .pos-home-img{
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
    }
    .pos-img{
        width: 90%;
    }
}
@media screen and (max-width: 650px) {
    .pos-home{
        margin-top: 10px;
    }
    .pos-text{
        width: 90%;
        text-align: center;
        font-size: 40px;
        line-height: 60px;
    }
    .pos-shadow{
        justify-content: center;
        align-items: center;
    }
   
    .shadow-pos{
        height: 60px
    }
    .pos-para p{
        font-size: 20px;
    }
    .pos-home-button button{
        width: 150px;
        font-size: 18px;
        height: 50px;
    }
    .pos-home-img{
        width: 100%;
        justify-content: center;
        align-items: center;
       margin-top: 1px;
    }
    .pos-img{
        width: 90%;
    }
    .pos-contact .contact-home{
        margin-top: 10px;
    }
}
.pos-icons{
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 100px;
    margin-top: 30px;
}
.pos-int{
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.pos-int img{
    width: 52px;
}
.pos-int p{
    font-size:32px ;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    color:rgba(75, 85, 99, 1) ;
    padding-top: 10px;
}
.pos-int .api{
    font-size: 16px;
    color: rgba(34, 34, 34, 1);
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
.pos-int-api{
    margin-top: 170px;
}
.apply{
    color: white;
    background-color:rgba(6, 132, 102, 1) ;
    border: 1px solid rgba(243, 244, 246, 1) ;
    font-weight: 500;
    font-size: 22px;
    font-family: 'Inter', sans-serif;
    width: 213px;
    height: 64px;
    border-radius: 12px;
    margin-top: 15px;
}
@media screen and (max-width: 1250px) {
    .pos-int p{
        font-size: 25px;
    }
}
@media screen and (max-width: 850px) {
    .pos-icons{
        flex-direction: column;
        height: auto;
       margin-top: 1px;
       
    }
    .pos-int{
        width: 100%;
        padding-top: 50px;
    }
    .pos-int p{
        font-size: 30px;
    }
}
@media screen and (max-width: 550px) {
    .pos-int p{
        font-size: 25px;
    }
    .pos-int-api{
        margin-top: 40px;
    }
    .apply{
        width: 150px;
        height: 50px;
        font-size: 17px;
    }
    .pos-footer .footer-main{
        margin-top: 40px;
    }
}

