.privacy-main{
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
}
.privacy{
    width: 1440px;
}
.privacy-head{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.privacy-heading p{
    font-family: 'Inter', sans-serif;
   font-size: 72px;
   font-weight: 500;
   line-height: 100px;
   text-align: center;
}
.privacy-text p{
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    color:rgba(75, 85, 99, 1) ;
    text-align: center;
}
.privacy-text{
    width: 70%;
    margin-top: 10px;
}
.privacy-card{
    margin-top: 80px;
}

.policy-cardss{
    display: flex;
 width: 100%;
 justify-content: center;
}
.policy{
    width: 72%;
   text-align: center;
}
.privacy-card-head p{
    font-size:32px ;
    font-weight: 500;
    line-height: 39px;
    font-family: 'Inter', sans-serif;
}
.privacy-card-text p{
    font-size:24px ;
    font-weight: 400;
    line-height: 29px;
    color:rgba(75, 85, 99, 1) ;
    font-family: 'Inter', sans-serif;

}
@media (max-width: 1300px) {
    .privacy-text{
        width: 80%;
    }

}
@media (max-width: 1000px) {
    .privacy-heading p{
        font-size: 55px;

    }
}
@media (max-width: 840px) {
    .privacy-heading p{
        font-size: 50px;
    }
    .privacy-text p{
        font-size: 22px;
    }
    .privacy-text{
        width: 90%;
    }
    .privacy-card-head p{
        font-size: 28px;
    }

    .privacy-card-text p{
        font-size: 20px;
    }
    .policy{
        width: 90%;
    }
    .privacy-card-text{
        
    }
}
@media (max-width: 550px) {
    .privacy-heading p{
        font-size: 40px;
    }
    .privacy-text p{
        font-size: 20px;
    }
    .privacy-card-head p{
        font-size: 22px;
    }
    .privacy-card-text p{
        font-size: 18px;
    }
}
@media (max-width: 400px) {
    .privacy-heading p{
        font-size: 30px;
        line-height: 60px;
       
    }
    .privacy-text p{
        font-size: 18px;
    }
    .privacy-card{
        margin-top: 40px;
    }
}