@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');
.main-div{
    max-width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
}
.homee{
    width: 1440px;
    overflow: hidden;
}
.appbar {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
  }
  
  .logo img {
    width: 173px;
    padding-top: 21px;
  }
  
  .bar {
    display: flex;
    width: 25%;
    justify-content: space-around;
    padding-right: 90px;
  }
  
  .bar button {
    background-color: transparent;
    color: black;
    border: none;
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
  }
  
  .sign-button-app {
    display: none;
  }
  
  .sign-home button {
    background-color: transparent;
    border: 1px solid rgba(243, 244, 246, 1);
    font-size: 22px;
    width: 200px;
    height: 60px;
    border-radius: 4px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    cursor: pointer;
  }
  
  .bar-size {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
  }
  
  .mobile-menu {
    display: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  /* New styles for the dropdown menu */
  .bar.show-menu {
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 70px;
    right: 0;
    margin-right: 10px;
    width: 30%;
    background-color: rgb(255, 255, 255);
    box-shadow: 2px 2px 5px rgb(196, 197, 195);
    z-index: 1;
    border-radius: 10px;
    justify-content: center;
  }
  
  .bar.show-menu button {
    width: 100%;
    padding: 15px;
    text-align: left;
  }
  
  .appbar {
    margin-bottom: 10px;
  }
  
  .logo img {
    width: 150px;
    padding-bottom: 20px;
    box-sizing: border-box;
    padding-left: 0 !important;
  }
  
  /* Media query for displaying the mobile menu icon */
  @media only screen and (max-width: 850px) {
    .bar {
      display: none;
    }
    .sign-home{display: none;}
  
    .bar.show-menu {
      display: flex;
    }
  
    .mobile-menu {
      display: block;
    }
  }
  
.main-home p {
    font-family: 'Inter', sans-serif;
    font-size: 72px;
    font-weight: 500;
}

.main-home {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 50px;
    margin-top: 100px;
    text-align: center;
}

.digtal-main {
    background-color: white;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 40px;
}

.digital {
    background-color: rgba(6, 132, 102, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    transform: rotate(-4deg);
    border-radius: 5px;
    width: 556px;
    height: 90px;
    padding-top: 10px;
}

.digital p {
    font-family: 'Inter', sans-serif;
    font-size: 72px;
    color: white;
}

.shadodw {
    background-color: rgba(6, 132, 103, 0.411);
    transform: rotate(4deg);
    border-radius: 5px;
    margin-bottom: 30px;
    width: 520px;
    display: flex;
    justify-content: center;
}

.home-text .text {
    max-width: 75%;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 30px;

}

.home-text {
    display: flex;
    justify-content: center;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 24px;
    color: rgba(75, 85, 99, 1);
}

.green {
    display: flex;
    justify-content: center;
}

.green button {
    background-color: rgba(34, 34, 34, 1);
    border: none;
    border-radius: 12px;
    height: 64px;
    width: 213px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: 'Inter', sans-serif;
    font-size: 22px;
    font-weight: 500;
}

.green button img {
    width: 30px;
    padding: 5px;
}

@media only screen and (max-width: 1160px) {
    .main-home p{
        font-size: 50px;
    }
    .digital p{
        font-size: 50px;
    }
    .digital{
        width: 400px;
    }
    .shadodw{
        width: 400px;
    }
    .home-text{
        font-size: 22px;
    }
    .green button{
        height: 55px;
        width: 150px;
        font-size: 15px;
    }
   

}
@media only screen and (max-width: 850px) {
    .digital{
        padding-top: 10px;
    }
}
@media only screen and (max-width: 780px) {
    .main-home p{
        font-size: 40px;
    }
    .digital p{
        font-size: 40px;
    }
    .digital{
        width: 330px;
        height: 70px;
    }
    .shadodw{
        width: 300px;
    }
    .home-text{
        font-size: 20px;
    }
    .green button{
        height: 45px;
        width: 120px;
        font-size: 12px;
    }
 
    .main-home{
        margin-top: 50px;
    }

}
@media only screen and (max-width: 650px) {
    .main-home p{
        font-size: 30px;
    }
    .digtal-main{
        margin-top: 10px;
        
    }
    .digital p{
        font-size: 30px;
    }
    .digital{
        width: 250px;
        padding-top: 10px;

    }
    .shadodw{
        width: 240px;
    }
    .home-text{
        font-size: 11px;
    }
    .green button{
        height: 45px;
        width: 120px;
        font-size: 12px;
    }
    .green button img{
        width: 25px;
    }
    
}
@media only screen and (max-width: 770px) {
    .appbar {
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .logo img {
        padding-left: 20px; /* Adjust padding for smaller screens */
    }

    .bar {
        width: 100%;
        margin-top: 10px;
        justify-content: space-evenly; /* Adjust margin for smaller screens */
    }

    .bar button {
        font-size: 14px; /* Adjust font size for smaller screens */
    }

   
}

@media only screen and (max-width: 470px) {
    .main-home p {
        font-size: 22px;
         /* Adjust font size for smaller screens */
    }
    .digital p{
        font-size: 22px;
      
    }
    .digital{
        width: 200px;
       height: 45px;
       padding-top: 15px;
      
    }
    .green button img{
        width: 30px;
    }
    .shadodw{
        width: 190px;
    }
  .home-text .text{
    width: 90%;
  }
  .main-home{
    margin-top: 20px;
  }
}
.ratings {
    display: flex;
    justify-content: center;
    margin-top: 40px;
}

.rating-pics {
    display: flex;
    justify-content: flex-end; /* Align pictures to the right */
    width: 15%;
}
.star-box{
    margin-bottom: 10px;
    
}


.pics {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    margin-left: -5%;
    margin-right: 5px; /* Adjust this value based on your preference */
    z-index: 1; /* Ensure pictures are positioned above each other */
}
.pics img{
    object-fit: contain;
    height: 100%;
    width: 100%;
}


@media only screen and (max-width: 1150px) {
    .star-box img{
        width: 20px;
    }
    .star-box{
        height: 20px;
    }
    
}
@media only screen and (max-width: 770px) {
    .ratings{
       padding-left: 100px;
    }
   .rating-pics{
    width: 150px !important;
   }
}
@media only screen and (max-width: 900px) {
    .starts .stars-text p{
        font-size: 12px;
        
    }
    
    .star-box{
        height: 20px;
        
    }
    .star-box img{
        width: 20px !important;
    }
}
@media only screen and (max-width: 630px) {
    .ratings{
        width: 100%;
        margin-bottom: -30px !important;
    }
    
    .star-box img{
        width: 10px;
    }
    .starts .stars-text p{
        font-size: 10px;
        

    }
  
    .rating-pics{
        width: 20% !important;
        
    }
    .rating-pics img{
        width: 25px;
        margin-left: -10px;
    }
   
  
}
@media only screen and (max-width: 690px) {
    .star-box{
        width: 950%;
    }
}

.starts{
   
    padding-left: 20px;
}
.starts .stars-text{
    font-size: 17px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
   color: rgba(75, 85, 99, 1);
}
.star-box img{
    padding-right: 5px;
    width: 25px;
}
@media only screen and (max-width: 1095px) {
    
}
@media only screen and (max-width: 770px) {
    .ratings{
        display: flex;
        justify-content: space-;
    }
    .starts{
        padding-right: 30px;
    }
}
.head-pics {
    display: flex;
    margin-top: 50px;
    justify-content: center;
    flex-wrap: wrap;
}

.imgs {
    width: 23%;
    display: flex;
    border-radius: 10px;
    flex-direction: column;
    justify-content: flex-end;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    margin-bottom: 16px; /* Add margin between columns */
}

.imgs img {
    object-fit: contain;
    width: 100%;
}

@media (max-width: 780px) {
    .head-pics {
        display: flex;
        flex-wrap: wrap;
    }

    .imgs {
        width: 50%;
        margin-bottom: 10px;
    }

    .imgs:nth-child(1),
    .imgs:nth-child(4) {
        order: 1; /* Set order for the first and fourth elements to 1 (display in the first row) */
    }

    .imgs:nth-child(2),
    .imgs:nth-child(3) {
        order: 2; /* Set order for the second and third elements to 2 (display in the second row) */
    }
}



.head{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 120px;
    margin-bottom: 50px;
    width: 100%;
}
.head-head{
    font-weight: 500;
    font-family: 'Inter', sans-serif;
   font-size: 42px;
   display: flex;line-height: 50px;
   justify-content: center;
   align-items: center;
   width: 90%;
   
   
}
.head-p{
   font-size: 24px;
   font-weight: 400;
   font-family: 'Inter', sans-serif;
  max-width: 72%;
  text-align: center;
  line-height: 29px;
  color: rgba(75, 85, 99, 1);

}

@media only screen and (max-width: 1740px) {
 
}
@media only screen and (max-width: 1330px) {
    .head-p{
        font-size: 18px;
        max-width: 75%;
    }

}
@media only screen and (max-width: 1200px) {
    .head-p{
        max-width: 80% !important;
    }
}
@media only screen and (max-width: 1000px) {
    .head-head{
        font-size: 28px;
    }
 
}




@media only screen and (max-width: 540px) {
    .head-head{
        font-size:25px;
        text-align: center;
    }
    .head-p{
        font-size:16px ;
        width: 80%;
    }
   .head{
    margin-top: 30px;
    margin-bottom: 40px;
   }
   .home-footer .footer-main{
    margin-top: 40px;
   }
   .iphone{
    width: 50%;
}

}



.cards-home {
    display: flex;
  }
  .card-2{
    display: flex;
  }
  
  .phone,
  .phone2 {
    width: 50%;
    display: flex;
    justify-content: center;
  }
  
  .phone {
    justify-content: flex-end;
  }
  .phone img{
    width: 305px;
    height: 614px;
    
  }
  .phone2 img{
    width: 305px;
    height: 614px;
  }
  .iphone img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .phone-cont{
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 29px;
    margin-left: 150px;
}
.phone-cont2{
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 29px;
    margin-right: 150px;
}
  
  .phone2 {
    justify-content: flex-start;
  }
  
  .phone-text {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  
  }
  .phone-text2{
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
  
  .phone-textt {
    width: 100%; /* Adjust width as needed */
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 24px;
    text-align: left;
    color: rgba(75, 85, 99, 1);
  }
  
  .phone-head {
    width: 100%;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 32px;
    height: 50px;
    text-align: left;
  }


  @media screen and (max-width: 1100px) {
    .phone-cont{
        width: 80%;
        margin-left: 100px !important ;
    }
    .phone-cont2{
        width: 80%;
        margin-right: 100px;
    }
    @media screen and (max-width: 900px) {
        .cards-home{
            flex-direction: column;
            align-items: center;
            width: 100%;
        }
      
        .phone-cont{
            width: 100%;
        }
        .phone-text{
            width: 100%;
            text-align: center;
        }
        .phone-cont{
            margin-left: 0 !important;
        }
        .card-2{
            display: flex;
            flex-direction: column-reverse;
            justify-content: center;
            align-items: center;
            width: 100%;
        }
        .phone2{
            width: 100%;
            justify-content: center;
            align-items: center;
        }
        .phone-cont2{
            margin-right: 0;
            width: 100%;
        }
        .phone-text2{
            width: 100%;
        }
        .phone-head{
            text-align: center;
        }
        
        .phone{
            width: 100%;
            justify-content: center;
        }
        .phone-textt{
            font-size: 20px;
            text-align: center;
        }
    }

  }
  @media screen and (max-width: 1250px) {
    .phone-head{
        font-size: 30px;
    }
  }
  @media screen and (max-width: 450px) {
    .phone-head{
        font-size: 23px;
    }
    .phone-textt{
        font-size: 13px;
        width: 90%;
    }
}
 
  
  .paper{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;
  }
  .paper-head{
    font-size:27px ;
    font-weight: 500;
    font-family: 'Poppins', sans-serif; 
    height: 60px;
}
.paper-text{
    font-size:18px ;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
}
@media screen and (max-width: 768px) {
    .paper-head{
        font-size: 20px;
    }
    .paper-text{
        font-size: 12px;
        width: 80%;
        text-align: center;
    }
}
.icon-main1 {
    display: flex;
    align-items: center;
    padding-left: 100px;
    padding-right: 100px;
    justify-content: center;
}

@media only screen and (max-width: 790px) {
    .icon-main1 {
        flex-direction: column;
        align-items: center;
      
    }
    .icon-main {
        width: 400px !important; /* Adjust the max-width based on your design */
        margin-bottom: 20px; 
    }
   
    .icon-head{
        font-size: 22px !important;
    }
    .icon-text{
        font-size: 18px !important;
    }
    .home-cont-div .contact-home{
        margin-top: 30px;
    }
}

.icon-main {
    width: 410px;
    display: flex;
    flex-direction: column;
    height: 260px;
    align-items: center;
  
}

.icon-head {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 32px;
    height: 45px;
    line-height: 39px;
}

.icon-text {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 24px;
    width: 70%;
    text-align: center;
    line-height: 29px;
    letter-spacing: -0.6px;
    color: rgba(75, 85, 99, 1);
}

.icon {
    height: 52px;
    width: 52px;
    margin-bottom: 20px;
}

.icon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@media screen and (max-width: 1360px) {
    .icon-main{
        width: 400px;
        height: 220px !important;
    }
    .icon-head{
        font-size: 28px;
    }
    .icon-text{
        font-size: 20px;
    }
}
@media screen and (max-width: 1150px) {
    .icon-main{
        width: 300px;
    }
    .icon-head{
        font-size: 25px;
    }
    .icon-text{
        font-size: 17px;
    }
}
@media screen and (max-width: 890px) {
    .icon-main{
        width: 250px;
        height: 190px;
    }
    .icon-head{
        font-size: 22px;
    }
    .icon-text{
        font-size: 12px;
    }
}

.contact-home {
    display: flex;
    justify-content: center;
    margin-bottom: 35px;
    margin-top: 100px;
}

.contact-head {
    font-size: 42px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}

.contact {
    display: flex;
    align-items: center;
    flex-direction: column; /* Set default flex direction to column */
}

.cont-text,
.cont-img {
    width: 100%; /* Both components take full width by default */
}

.cont-text {
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 40px;
    font-size: 15px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    color: rgba(75, 85, 99, 1);
}

.cont-img {
    height: 200px;
    width: 250px;
    display: flex;
    justify-content: center;
}

.contact-text {
    width: 90%;
    display: flex;
    flex-direction: column;
    font-family: 'Inter', sans-serif;
    margin-top: -200px;

}

.contact-text img {
   width: 20px;
    margin-bottom: 15px;
}
.cont-img{
    width: 60%;
    height: 300px;
    margin-bottom: -50px;
   margin-top: -200px;
}

.cont-img img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    border-radius: 10px;
}


@media (min-width: 850px) {
    
    .contact {
        flex-direction: row; /* Change flex direction to row for screens wider than 790px */
        width: 100%;
        justify-content: center;

    }
    .cont-text img{
        width: 35px;
    }

    .cont-text {
        width: 45%; 
        height: auto;
        font-size:24px ;
      line-height: 29px;
      font-family: 'Inter', sans-serif;
      margin-top: 150px;

     
    }

    .cont-img {
        width: 45%; /* Adjust width for cont-img */
        height: auto;
      
        margin-top: 20px;
    }
}
.get-app{
    display: flex;
    justify-content: center;
}
.app-buttons{
    width: 31%;
    display: flex;
    justify-content: space-between;
}
.app-buttons button{
    height: 64px;
    width: 213px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border: 1px solid rgba(243, 244, 246, 1);
    background-color: transparent;
    font-size: 22px;
}
.app-buttons button img{
    width: 24px;
}
@media screen and (max-width: 1200px) {
    .app-buttons button{
        width: 180px;
        height: 50px;
        font-size: 15px;
    }
}
@media screen and (max-width: 758px) {
    .app-buttons button{
        font-size: 8px;
        width: 130px;
    }
    .app-buttons{
        width: 50%;
    }
    .app-buttons button img{
        width: 10px;
    }
  
}
@media screen and (max-width: 960px) {
    .app-buttons button{
        font-size: 13px;
        width: 130px;
    }
    .app-buttons{
        width: 30%;
    }
    .app-buttons button img{
        width: 15px;
    }
}
@media screen and (max-width: 640px) {
    .app-buttons button{
        font-size: 13px;
        width: 130px;
        height: 40px;
    }
    .app-buttons{
        width: 40%;
    }
    .app-buttons button img{
        width: 15px;
    }

}
@media screen and (max-width: 450px) {
    .app-buttons button{
        font-size: 10px;
        width: 130px;
    }
    .app-buttons{
        width: 50%;
    }
    .app-buttons button img{
        width: 10px;
    }
   
}

@keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: rgba(6, 132, 102, 1);
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    animation: fadeInDown 0.5s ease-in-out;
    display: none; /* initially hide the button */
  }
  
  .scroll-to-top.show {
    display: block; /* show the button when scrolling down */
  }
  .contact-home.animate {
    animation: slideFromLeft 1s ease-out;
  }
  
  .contact.animate {
    animation: slideFromLeft 1s ease-out;
  }
  
  @keyframes slideFromLeft {
    from {
      opacity: 0;
      transform: translateX(-50px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  .loading-spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
  }
  
  

  
  


