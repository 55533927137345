/* Import Inter font if you haven't already */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');


@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');

/* Apply styles to your elements */
.main-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh !important;
    margin: 0;
   overflow: hidden;
}
*{
    margin: 0;
}
.main {
    background-color: rgb(255, 255, 255);
    height: 100% !important;
    width: 100%;
    display: flex;
}
@media only screen and (max-height: 660px) {
  .main2{
   padding-top: 1% !important;
  }
  .main3{
    height: 60% !important;
  }
   .Register{
    margin-top: 300px !important;
   }
}

.main2 {
    background-color: rgba(6, 132, 102, 1);
    width: 32%; /* Adjust the width as needed */
    box-sizing: border-box; 
    flex-direction: column; 
    display: flex;
    height: 100vh;
    padding-top: 5%;
}

.main2 .text {
    color: white;
    font-size: 70px; 
    font-weight: 500;
    font-family: 'Inter', sans-serif; 
    padding-left: 32px;
    display: flex;
    align-items: center;
    line-height: 87px;

}
.main2 .text2{
    color: white;
    font-size: 20px;
    font-family: 'Inter', sans-serif; 
    font: 400;
    padding-left: 32px;
    padding-bottom: 20px;
    padding-top: 3%;

}
@media screen and (max-width: 1080px) {
  

    .main2 .text {
        font-size: 3.2vw;
        padding-left: 10px;
    }
    .main2 .text2 {
        font-size: 20px;
        padding-left: 10px;
    }
}
.card1 {
    width: 90% !important;
    max-width: 90%; /* Set a maximum width for the card */
    height:276px; /* Adjust height to auto for responsiveness */
    background-color:#ffffff49 ;
    border-radius: 12px;
    box-sizing: border-box; /* Include padding in the total width/height */
    margin: 0 auto;
    
  
}

.card-message {
   width: 80%;
  padding-top: 20px;
}

.massege {
    color: white;
    font-size: 20px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    text-align: start;
    line-height:28px ;
}

.card-title {
    display: flex;
    height: 55px;
    justify-content: flex-start; 
    padding-left: 3px;
    margin-top: 70px;
    padding-left: 50px;
}

.profile-name {
    color: white;
    font-size: 18px;
    font-weight: 100;
    font-family: 'Inter', sans-serif;
    padding-left: 16px;
    height: 20px;
}

.profile h2 {
    font-weight: 500;
}

.profile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 20px;
    height: 25px;
    padding-top: 5px;
   text-align: start;
}
.card-cont {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 8%;
}
@media only screen and (max-width: 1100px) {
    .main2{
        display: none;
    }
}

@media only screen and (max-width: 1810px) {
    .massege{
        font-size: 15px;
    }
}
@media only screen and (max-width: 1720px) {
    .main2 .text{
        font-size: 60px;
        line-height: 60px;
    }
    .card1{
        height: 250px;
    }
  .card-title{
    margin-top: 50px;
  }
}


@media only screen and (max-width: 1450px) {
    .main2 .text{
        font-size: 50px;
    }
    .card-title{
        margin-top: 10px;
       padding-left: 40px;
    }
    .card1{
        height: 200px;
    }
    .profile-name{
        font-size: 15px;
    }
    .card-title img{
        width: 45px;
        height: 45px;
    }
}
@media only screen and (max-width: 1450px) {
    .main2 .text{
        font-size: 45px;
    }
    .main2 .text2{
        font-size: 15px;
    }
  .massege{
    font-size: 12px;
  }
  .card{
  }
}

@media only screen and (max-width: 600px) {
    .card1 {
        width: 100%; /* Full width for small screens */
        max-width: none; /* Remove maximum width for small screens */
    }

    .card-title {
        margin-top: 5%; /* Adjust margin for smaller screens */
    }

    .profile-name {
        font-size: 14px; /* Adjust font size for smaller screens */
    }

}
.slick-dots li button:before {
    color: #ffffff !important; /* Set the color to white */
}

.slick-dots li.slick-active button:before {
    color: #ffffff !important; /* Set the color to white */
}

.slick-dots li button {
    background-color: transparent !important; /* Set background color to transparent */
}
.main3{
    width: 100%;
    display: flex;
   justify-content: center;
   align-items: center;
   
}
.Sign{
    width: 70%;
    height: auto;
    
}
.Sign .sign-in{
    font-family: 'Inter', sans-serif;
   color: black;
   font-weight: 500;
  font-size: 32px;
  
}

@media only screen and (max-width: 900px) {
    .Sign .sign-in{
        font-size: 25px;
    }
    .Sign p{
        font-size: 20px;
    }

}
.Sign p{
    font-family: 'Inter', sans-serif;
    margin-bottom: 25px;
    color: rgba(75, 85, 99, 1);
    font-size: 24px;
}
.button{
    background-color: transparent;
    border: none;
    border-bottom: 2px solid rgba(75, 85, 99, 1);
    height: 45px;
    padding-bottom: 10px;
    font-size: 24px;
    font-weight: 500;
    width:150px ;
    color: rgba(75, 85, 99, 1);
    font-family: 'Inter', sans-serif;
    opacity: 40%;

}

.button.active {
    background-color: transparent;
    color: rgba(6, 132, 102, 1); 
    border: none;
    border-bottom: 2px solid rgba(6, 132, 102, 1);
    opacity: 100%;
  }
  .fields{
    display: flex;
    flex-direction: column;
    
  }
   input:focus{
    outline: rgb(255, 255, 255);

  }
  .fields input{
     margin-top: 25px;
     height: 64px;
     border: 1px solid rgba(243, 244, 246, 1);
     color: rgba(75, 85, 99, 1);
     width: 60%;
     border-radius: 6px;
     font-weight: 400;
     font-size: 20px;
     padding-left: 32px;
  }
.check{
    width: 60%;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}
.check label{
    color: rgba(75, 85, 99, 1);
    font-size: 18px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    padding-left: 5px;
}
/* Add this style to your existing CSS file */
.custom-checkbox {
    position: relative;
    padding-left: 30px; /* Adjust as needed */
    cursor: pointer;
  }
  
  .custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  .check1 {
    position: relative;
    padding-left: 35px; /* Adjust as needed */
    margin-bottom: 12px;
    display: inline-block;
    vertical-align: top;
  }
  
  .check1 input:checked ~ .checkmark {
    border-color: rgb(255, 253, 253);
  }
  
  .check1 input:checked ~ .checkmark::before {
    content: '\2713'; /* Unicode character for checkmark */
    display: block;
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translate(-50%, -50%);
    color: rgb(255, 255, 255);
    background-color: rgba(6, 132, 102, 1);
    border-radius: 2px;
    height: 14px;
    width: 14px;
    text-align: center;
    line-height: 14px;
  }
  
  .check1 .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid rgba(243, 244, 246, 1); /* Default border color */
    border-radius: 3px;
  }
  
  
  
  /* Style for the label text */
  .custom-checkbox label {
    /* Add any additional styling for the label text */
  }
  
  
  .check1 .checkmark {
    position: absolute;
    top: 0;
    left: -20px;
    height: 15px;
    width: 15px;
    margin-top: 5px;
    border: 1px solid rgba(243, 244, 246, 1); /* Default border color */
    border-radius: 3px;
  }
.check input[type="checkbox"] {
  border: 2px solid red !important;
  padding: 5px;
}

.check input[type="checkbox"]:checked {
  background-color: red !important; /* Optional: Change background color when checked */
}
.Login-button{
    width: 60%;
    background-color: rgba(6, 132, 102, 1) ;
    height: 50px;
    margin-top: 30px;
    margin-bottom: 15px;
    border-radius: 6px;
    border: 1px solid rgba(243, 244, 246, 1) ;
    color: white;
}
.already{
    background-color: transparent;
    border: none;
    color: rgba(75, 85, 99, 1);
    margin-top: 15px;
    font-size: 20px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
}
.forget{
    background-color: transparent;
    border: none;
    color:rgba(75, 85, 99, 1) ;
    font-weight: 400;
    font-size: 18px;
    font-family: 'Inter', sans-serif;
}
button{
    cursor: pointer;
}
.main4{
    width: 100%;
    height: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: scroll;
}
.Register{
    width: 75%;
   height: auto;
}
.Register .sign-in{
    font-family: 'Inter', sans-serif;
   color: black;
   font-weight: 500;
  font-size: 32px;
}
.Register p{
    font-family: 'Inter', sans-serif;
    font-size:24px ;
    padding-bottom: 30px;
}
input::placeholder{
    color:rgba(75, 85, 99, 0.466);
}
.rbutton{
    background-color: transparent;
    border: none;
    border-bottom: 2px solid rgba(75, 85, 99, 1);
    height: 45px;
    padding-bottom: 10px;
    font-size: 24px;
    font-weight: 500;
    width:230px ;
    color: rgba(75, 85, 99, 1);
    font-family: 'Inter', sans-serif;
    opacity: 40%;

}
.rbutton.active {
    background-color: transparent;
    color: rgba(6, 132, 102, 1); 
    border: none;
    border-bottom: 2px solid rgba(6, 132, 102, 1);
    opacity: 100%;
  }
@media only screen and (max-width: 1600px) {
    .button{
        width: 100px;
        font-size: 18px;
    }
   
   
    .already{
        font-size: 15px;
    }
    .check label{
        font-size: 15px;
    }
    .forget{
        font-size: 15px;
    }
    .Sign{
        height: 70%;
    }
    .Login-button{
        height: 35px;
    }
}
@media only screen and (max-width: 1600px) {
    .rbutton{
        width: 180px;
        font-size: 18px;
    }
   
    .Register p{
        font-size: 20px;
    }
  
    .already{
        font-size: 15px;
    }
    .check label{
        font-size: 15px;
    }
    .forget{
        font-size: 15px;
    }
  
    .Login-button{
        height: 35px;
    }
}
@media only screen and (max-width: 1100px) {
    .Sign{
        height: auto;
        width: 60%;
    }
    .main3{
        
    }
    .Sign p{
        font-size: 13px;
        margin-left: 10%;
    }
    .button{
        width: 150px;
        margin-left: 10%;
        font-size: 20px;
    }
    .fields input{
        margin-left: 10%;
        width: 80%;
    }
    .already{
        margin-left: 10%;
        font-size: 12px;
    }
    .forget{
        font-size: 12px;
    }
    .check{
        width: 100%;
        display: flex;
        align-items: start;
        flex-direction: column;
        justify-content: space-around;
        padding-left: 10%;
    }
    .check label{
        font-size: 13px;
    }
    .check input{
        width: 12px;
    }
    .Login-button{
        margin-left: 10%;
        width: 80%;
    }

    .rbutton{
        width: 170px;
        font-size: 20px;
    }
    
    .register-f .fields input{
       margin-left: 10%;
    }
    .already{
        font-size: 15px;
    }
    .check label{
        font-size: 15px;
    }
    .forget{
        font-size: 15px;
    }
    
    .Login-button{
        height: 35px;
    }
   
   
    .Register p{
        font-size: 18px;
        margin-left: 10%;
        }
    .rr-button{
        margin-left: 10%;
    }
}
@media only screen and (max-width: 600px) {
    .Sign{
        
        width: 90%;
    }
    .Sign p{
        font-size: 13px;
        margin-left: 10%;
    }
    .button{
        width: 90px;
        margin-left: 10%;
        font-size: 13px;
    }
    .fields input{
        margin-left: 10%;
        width: 80% !important;
        height: 50px;
    }
    .already{
        margin-left: 10%;
        font-size: 12px;
    }
    .forget{
        font-size: 12px;
    }
    .check{
        width: 100%;
        display: flex;
        align-items: start;
        flex-direction: column;
        justify-content: space-around;
        padding-left: 10%;
    }
    .check label{
        font-size: 13px;
    }
    .check input{
        width: 12px;
    }
    .Login-button{
        margin-left: 10%;
        width: 80% !important;
    }

    .rbutton{
        width: 120px;
        font-size: 13px;
    }
    
    .register-f .fields input{
       margin-left: 10%;
    }
    .already{
        font-size: 15px;
    }
    .check label{
        font-size: 15px;
    }
    .forget{
        font-size: 15px;
    }
    .Register{
       
    }
    .Login-button{
        height: 35px;
    }
    .Register{
        
        width: 100%;
    }
    .Register{
        width: 100%;
       
    }
    .Register p{
        font-size: 15px;
        margin-left: 10%;
        margin-bottom: 10px;
        line-height: 1px;
        }
    .rr-button{
        margin-left: 10%;
    }
}
