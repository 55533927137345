.contact-us-main{
    width: 100%;
    justify-content: center;
    display: flex;
    overflow: hidden;
}
.contact-us{
    width: 1440px;
   
}
.contactt-mid{
    display: flex;
    justify-content: center;
}
.contactt{
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 60px;
   
}
.contactt-text{
    width: 55%;
    font-size: 72px;
    font-weight: 500;
    line-height: 100px;
    font-family: 'Inter', sans-serif;

}
.contactt-img{
    width: 45%;
    display: flex;
    justify-content: end;
}
.conatctt-head{
    display: flex;
}
.contactt-shadow-text{
    background-color: rgba(6, 132, 102, 1);
    color: white;
    border-radius: 8px;
    padding-left: 10px;
    padding-right: 10px;
    transform: rotate(-4deg);
}
.contactt-shadow{
    background-color: rgba(180, 218, 209, 1);
    transform: rotate(4deg);
    height: 100px;
    border-radius: 8px;
    margin-left: 10px;
}
.contactt-para{
    font-size:24px ;
    font-family: 'Inter', sans-serif;
   font-weight: 400;
   line-height: 29px;
   color:rgba(75, 85, 99, 1) ;
}
.contactt-flex{
    display: flex;
   justify-content: space-between;
   margin-top: 50px;
}
.contactt-icon p{
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    color:rgba(75, 85, 99, 1) ;
    font-family: 'Inter', sans-serif;
    padding-top: 5px;
    padding-left: 20px;

}
.contactt-icon img{
    width:42px ;
    height: 42px;
}
.contactt-icon{
    display: flex;  
    
}
.contactt-phone{
    display: flex;
    width: 85%;
    justify-content: space-between;
}
.contactt-pic{
    width: 75%;
    border-radius: 12px;
}
.contactt-pic img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.faqss{
    font-size: 42px;
    font-weight: 500;
    line-height: 59px;
    font-family: 'Inter', sans-serif;
    margin-top: 30px;

}
@media screen and (max-width: 1350px) {
    .contactt-phone{
        width: 80%;
    }
}
@media screen and (max-width: 1250px) {
    .contactt-phone{
        width: 90%;
    }
}
@media screen and (max-width: 1100px) {
    .contactt-icon p{
        font-size: 20px;
    }
}
@media screen and (max-width: 1000px) {
    .contactt-phone{
        width: 100%;
    }
}
@media screen and (max-width: 1250px) {
    .conatctt-head{
        font-size: 55px;
        line-height: 70px;
    }
    .contactt-head2 p{
        font-size: 55px;
        line-height: 70px;
    }
    .contactt-shadow{
        height: 70px;
    }
}
@media screen and (max-width: 1080px) {
    .contactt-pic{
        width: 90%;
    }
}
@media screen and (max-width: 950px) {
    .conatctt-head{
        font-size: 45px;
        line-height: 50px;
    }
    .contactt-head2 p{
        font-size: 45px;
        line-height: 50px;
    }
    .contactt-shadow{
        height: 50px;
    }
    .contactt-para{
        font-size: 20px;
    }
    .contactt-icon p{
        padding-left: 10px;
    }
}
@media screen and (max-width: 850px) {
    .conatctt-head{
        font-size: 40px;
        line-height: 45px;
    }
    .contactt-head2 p{
        font-size: 40px;
        line-height: 45px;
    }
    .contactt-shadow{
        height: 45px;
    }
    .contactt-para{
        font-size: 17px;
    }
    .contactt-pic{
        width: 95%;
    }
    .contactt-icon p{
        font-size: 17px;
    }
}
@media screen and (max-width: 750px) {
    .conatctt-head{
        font-size: 35px;
    }
    .contactt-head2 p{
        font-size: 35px;
    }
    .contactt-para{
        font-size: 15px;
    }
    .contactt-pic{
        width: 95%;
    }
    .contactt-icon p{
        font-size: 15px;
    }
    .contactt-icon img{
        height: 25px;
        width: 25px;
        margin-top: 10px;
    }
}@media screen and (max-width: 650px) {
    .contactt{
        flex-direction: column;
    }
    .contactt-text{
        width: 90%;
       text-align: center;
       justify-content: center;
    }
    .conatctt-head{
        width: 100%;
        justify-content: center;
    }
    .conatctt-head p{
        text-align: center;
        font-size: 45px;
        line-height: 70px;
    }
    .contactt-head2 p{
        text-align: center;
        font-size: 45px;
        line-height: 70px;
    }
    .contactt-shadow{
        height: 70px;
    }
    .contactt-para p{
        font-size: 20px;
    }
    .contactt-phone{
        justify-content: space-evenly;
        margin-bottom: 20px;
    }
    .contactt-img{
        width: 100%;
    }
}
@media screen and (max-width: 475px) {
    .conatctt-head p{
        font-size: 35px;
        line-height: 50px;
    }
    .contactt-head2 p{
        font-size: 35px;
        line-height: 50px;
    }
    .contactt-shadow{
        height: 50px;
    }
    .contactt-para p{
        font-size: 17px;
    }

}
@media screen and (max-width: 475px) {
    .conatctt-head{
        flex-wrap: wrap;
    }
    .contactt-icon p{
        font-size: 12px;
    }
    .contactt-icon img{
        width: 20px;
        height: 20px;
    }
    .contactt{
        margin-top: 20px;
    }
}