.guide-home{
    max-width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
}
.guide-homee{
    width: 1440px;
    overflow: hidden;
}
.guidee-main{
    width: 100%;
    display: flex;
   height: 920px;
   margin-top: 100px;
}
.guidee-text{
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
}
.guidee-img{
    width: 55%;
    display: flex;
    justify-content: center;
}
.guidee-image{
    width: 100%;
    height: 100%;
}
.guidee-image img{
    height: 100%;
    width: 100%;
    object-fit: contain;
}
.guidee-head{
    width: 80%;
   
    font-weight: 500;
    font-size: 72px;
    font-family: 'Inter', sans-serif;
    line-height: 100px;
}
.guidee-card{
    width: 80%;
   height: 100%;
    padding-left:40px ;
    box-shadow: 5px 5px 48px rgba(16, 24, 40, 0.08) ;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 12px;
    
}
.guidee-shadow{
    font-weight: 500;
    font-size: 72px;
    font-family: 'Inter', sans-serif;
    line-height: 100px;
    display: flex;
}
.guidee-box{
    background-color:rgba(180, 218, 209, 1) ;
    transform: rotate(4deg);
    height: 100px;
    border-radius: 8px;
    margin-left: 8px;
    margin-right: 8px;
}
.guidee-boxx{
    background-color: rgba(6, 132, 102, 1);
    transform: rotate(-4deg);
    color: white;
    border-radius: 8px;
    padding-left: 5px;
    padding-right: 5px;
}
.guidee-steps .g-step{
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color:rgba(75, 85, 99, 1) ;
    padding-top: 10px;
}
.guidee-steps p{
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 24px;
    color: black;
    line-height: 30px;
}
@media only screen and (max-width: 1250px) {
    .guidee-shadow p{
        font-size: 60px;
        line-height: 80px;
    }
    .guidee-head p{
        font-size: 60px;
        line-height: 80px;
    }
    .guidee-box{
        height: 80px;
    }
    .guidee-main{
        height: 850px;
    }
    .guidee-steps p{
        font-size: 20px;
    }
    .guidee-steps .g-step{
        font-size: 15px;
    }
}
@media only screen and (max-width: 1000px) {
    .guidee-shadow p{
        font-size: 50px;
    }
    .guidee-head p{
        font-size: 50px;
        line-height: 60px;
    }
    .guidee-box{
        height: 60px;
    }
    .guidee-main{
        height: 800px;
    }
}
@media only screen and (max-width: 850px) {
    .guidee-image{
        width: 100%;
    }
    .guidee-img{
        width: 50%;
    }
    .guidee-head p{
        font-size: 40px;
        line-height: 50px;
    }
    .guidee-box{
        height: 50px;
    }
    .guidee-main{
        height: 600px;
        margin-top: 40px;
    }
    .guidee-steps p{
        font-size: 18px;
    }
}
@media only screen and (max-width: 650px) {
    .guidee-main{
        flex-direction: column;
        height: auto;
        margin-top: 20px;
    }
    .guidee-text{
        width: 100%;
    }
    .guidee-head{
        text-align: center;
    }
    .guidee-head p{
        font-size: 60px;
        line-height: 80px;
    }
    .guidee-box{
        height: 80px;
    }
    .guidee-shadow{
        text-align: center;
        justify-content: center;
    }
    .guidee-img{
        width: 100%;
        padding-top: 20px;
    }
    .guidee-image{
        width: 80%;
    }
    .guidee-steps p{
        font-size: 20px;
    }
    .guidee-steps .g-step{
        font-size: 16px;
    }
}
@media only screen and (max-width: 550px) {
    .guidee-head p{
        font-size: 40px;
        line-height: 50px;
    }
    .guidee-box{
        height: 50px;
    }
    .guidee-steps p{
        font-size: 17px;
    }
    .guidee-contact .contact-home{
        margin-top: 30px;
    }
    .guidee-footer .footer-main{
        margin-top: 40px;
    }
}




@keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: rgba(6, 132, 102, 1);
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    animation: fadeInDown 0.5s ease-in-out;
    display: none; /* initially hide the button */
  }
  
  .scroll-to-top.show {
    display: block; /* show the button when scrolling down */
  }
  